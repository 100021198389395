/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import TestimonialCard from "../../components/TestimonialCard";
import TestimonialSwiper from "../../components/TestimonialSwiper";
import useViewport from "../../hooks/useViewport";
import config from "../../network/config";
import "./styles.scss";

const Testimonials = ({ data }) => {
  return (
    <div className="testimonialsWrapper">
      <Container fluid>
        <h2 className="heading bold">Testimonials</h2>
        <Container className="testimonialsContainer">
          <div>
            <TestimonialSwiper data={JSON.parse(data)} />
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Testimonials;
