/* eslint-disable */
import React, { useEffect, useState } from "react";

import Slideshow from "../../components/Slideshow";
import Projects from "../../components/Projects";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";

import Services from "../Services";
import About from "../About";
import Clients from "../Clients";
import ContactUs from "../ContactUs";
import Testimonials from "../Testimonials";

import axios from "axios";

import {
  getAboutUs,
  getCategories,
  getClients,
  getContactSection,
  getFooter,
  getHeroSlider,
  getProjects,
  getServices,
  getSocials,
  getTeam,
  getTestimonials,
} from "../../network/ApiAxios";
import Loading from "../../components/Loading";
import PopUp from "../../components/PopUp";
import _ from "lodash";
import TeammatePopUp from "../../components/TeammatePopUp";
import ContactPage from "../ContactPage";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [heroSliderData, setHeroSliderData] = useState([]);
  const [projectsData, setProjectsData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  const [contactSection, setContactSectionData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState(null);
  const [clientsData, setClientsData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [servicesData, setServicesData] = useState([]);
  const [splitProjectsData, setSplitProjectsData] = useState([]);

  const split = (data) => {
    let projects = data.data;
    let SPC = [];

    data.categories.forEach((cat) => {
      let arr = [];
      for (let x = 0; x < projects.length; x++) {
        const element = projects[x];
        for (let index = 0; index < element?.categories?.length; index++) {
          if (_.isEqual(element?.categories[index]?.categories_id, cat)) {
            arr.push(element);
          }
        }
      }

      SPC.push({ name: cat.name, array: JSON.stringify(arr), id: cat.id });
    });

    setSplitProjectsData(SPC);
  };

  const getLandingPage = () => {
    axios
      .all([
        getHeroSlider(),
        getProjects(),
        getCategories(),
        getTestimonials(),
        getTeam(),
        getAboutUs(),
        getClients(),
        getSocials(),
        getFooter(),
        getServices(),
        getContactSection(),
      ])
      .then(
        axios.spread(
          (
            dataHeroSlider,
            dataProjects,
            dataCategories,
            dataTestimonials,
            dataTeam,
            dataAboutUs,
            dataClients,
            dataSocials,
            dataFooter,
            dataServices,
            dataContactSection
          ) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 3000);
            setHeroSliderData(dataHeroSlider.data.data);
            setProjectsData(dataProjects.data.data);

            setCategoriesData(dataCategories.data.data);
            setTeamData(dataTeam.data.data);
            setTestimonialsData(JSON.stringify(dataTestimonials.data.data));
            setAboutUsData(dataAboutUs.data.data);
            setClientsData(dataClients.data.data);
            setContactSectionData(dataContactSection.data.data);
            setSocialsData(dataSocials.data.data);
            setFooterData(dataFooter.data.data);
            setServicesData(dataServices.data.data);
            split({
              categories: dataCategories.data.data,
              data: dataProjects.data.data,
            });
          }
        )
      );
  };

  useEffect(() => {
    getLandingPage();
  }, []);

  const [selectedYoutubeLink, setSelectedYoutubeLink] = useState(null);
  const [selectedTeammate, setSelectedTeammate] = useState(null);
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  return (
    <>
      <Loading isLoading={isLoading} />
      <NavBar />
      {selectedYoutubeLink && (
        <PopUp
          selectedYoutubeLink={selectedYoutubeLink}
          onHide={() => {
            setSelectedYoutubeLink(null);
          }}
          show={selectedYoutubeLink !== null}
        />
      )}
      <ContactPage
        onHide={() => {
          setIsOpenMessage(false);
        }}
        setIsOpenMessage={setIsOpenMessage}
        show={isOpenMessage}
      />
      {selectedTeammate && (
        <TeammatePopUp
          selectedTeammate={selectedTeammate}
          onHide={() => {
            setSelectedTeammate(null);
          }}
          setSelectedTeammate={setSelectedTeammate}
          show={selectedTeammate !== null}
        />
      )}

      {!isLoading && (
        <Slideshow data={heroSliderData} socialsData={socialsData} />
      )}
      <Projects
        data={{
          categories: categoriesData,
          data: JSON.stringify(projectsData),
        }}
        splitProjectsData={splitProjectsData}
        isLoading={isLoading}
        setSelectedYoutubeLink={setSelectedYoutubeLink}
      />
      <Services servicesData={servicesData} />
      <About
        data={{ about: aboutUsData, team: teamData }}
        setSelectedTeammate={setSelectedTeammate}
      />
      <Clients data={clientsData} />
      {/* <Testimonials data={testimonialsData} /> */}
      <ContactUs data={contactSection} setIsOpenMessage={setIsOpenMessage} />
      <Footer socialsData={socialsData} footerData={footerData} />
    </>
  );
};

export default Home;
