import React from "react";
import { Container } from "react-bootstrap";
import MloukButton from "../../components/MloukButton";
import "./styles.scss";

import bottomRightSquare from "../../assets/images/bottomRightSquare.svg";
import lgBottomLeftSquares from "../../assets/images/lgBottomLeftSquares.svg";
import mdBottomLeftSquares from "../../assets/images/mdBottomLeftSquares.svg";
import smBottomLeftSquares from "../../assets/images/smBottomLeftSquares.svg";
import topRightSquares from "../../assets/images/topRightSquares.svg";

const ContactUs = ({ data, setIsOpenMessage }) => {
  return (
    <Container className="contactUsContainer" id="contact">
      <img
        src={lgBottomLeftSquares}
        className="contactUsLgBottomLeftAssets"
        alt="contactUsLgBottomLeftAssets"
      />
      <img
        src={mdBottomLeftSquares}
        className="contactUsMdBottomLeftAssets"
        alt="contactUsMdBottomLeftAssets"
      />
      <img
        src={smBottomLeftSquares}
        className="contactUsSmBottomLeftAssets"
        alt="contactUsSmBottomLeftAssets"
      />
      <img
        src={bottomRightSquare}
        className="contactUsBottomRightAssets"
        alt="contactUsBottomRightAssets"
      />
      <img
        src={topRightSquares}
        className="contactUsTopRightAssets"
        alt="contactUsTopRightAssets"
      />

      <h1 className="contactUsHeading">{data?.title}</h1>
      <h4 className="contactUsSubheading">{data?.text}</h4>

      <div className="contactUsButtonRow">
        {/* <MloukButton
          style={{ marginInline: 16, marginBlock: 8 }}
          text="Find out"
          kind="blue"
          onClick={() => {
            window.open("https://goo.gl/maps/RhdmTYQnxzp16tbk9");
          }}
        /> */}
        <MloukButton
          style={{ marginInline: 16, marginBlock: 8 }}
          text={data.buttonLabel}
          kind="red"
          onClick={() => {
            setIsOpenMessage(true);
          }}
        />
      </div>
    </Container>
  );
};

export default ContactUs;
