import React from "react";
import { Container } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

import config from "../../network/config";
import "./styles.scss";

const TeammateDetails = ({ setSelectedTeammate, selectedTeammate }) => {
  return (
    <div className="teammateDetails">
      <IoClose
        className="closeIcon"
        size={32}
        onClick={() => {
          setSelectedTeammate(null);
        }}
      />

      <Container>
        <div className="teammateInfoRow">
          <div
            className="portraitSquare"
            style={{
              backgroundImage: `url(${config.WS_STORAGE_URL}${selectedTeammate?.image})`,
            }}
          />

          <div className="infoSection">
            <div className="topInfoSection">
              <h1 className="heading">{selectedTeammate?.name}</h1>
              <h1 className="subheading">{selectedTeammate?.title}</h1>
            </div>
            <h4 className="paragraph">{selectedTeammate?.biography}</h4>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TeammateDetails;
