import React from "react";
import "./styles.scss";

const TestimonialCard = ({ name, paragraph, imgUrl }) => {
  return (
    <div className="testimonialCardWrapper">
      <div className="testimonialCardInfoSection">
        <div className="image">
          <img src={imgUrl} alt={name} />
        </div>
        <div className="name">
          <h2 className="bold">{name}</h2>
        </div>
      </div>
      <div className="testimonialCardParagraph">
        <h4>{paragraph}</h4>
      </div>
    </div>
  );
};

export default TestimonialCard;
