import React from "react";
import { Modal } from "react-bootstrap";
import useViewport from "../../hooks/useViewport";
import "./styles.scss";

const PopUp = (props) => {
  const { width } = useViewport();

  function getWidth() {
    if (width > 1200) {
      return 1008;
    }
    if (width > 800) {
      return 672;
    }
    if (width > 600) {
      return 560;
    }
    if (width > 380) {
      return 360;
    }
    return 300;
  }

  function getHeight() {
    if (width > 1200) {
      return 567;
    }
    if (width > 800) {
      return 378;
    }
    if (width > 600) {
      return 315;
    }
    if (width > 380) {
      return 202;
    }
    return 169;
  }

  return (
    <Modal lg centered {...props}>
      <iframe
        width={getWidth()}
        height={getHeight()}
        src={props.selectedYoutubeLink}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Modal>
  );
};

export default PopUp;
