import React from "react";
import "./styles.scss";
import { RiExternalLinkLine } from "react-icons/ri";

const BusinessCard = ({ email, country, phone, location, url }) => {
  return (
    <div className="businessCard">
      <p className="name">{country}</p>
      <p
        className="location"
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        {location}
        <sup>
          <RiExternalLinkLine size={16} />
        </sup>
      </p>
      <br />
      <p>{email}</p>
      <p>{phone}</p>
    </div>
  );
};

export default BusinessCard;
