import React from "react";
import "./styles.scss";

const TextArea = ({ name, type, onChange, value, placeholder, ...rest }) => {
  return (
    <textarea
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      className="input-textarea"
      {...rest}
    />
  );
};

export default TextArea;
