import React from "react";
import config from "../../network/config";
import "./styles.scss";

const SocialHandles = ({ socialsData }) => {
  return (
    <div className="socialHandles">
      {socialsData?.map(
        (item) =>
          item.primary && (
            <div
              className="socialHandle"
              key={item.id}
              onClick={() => window.open(item.url, "_blank")}
            >
              <img src={config.WS_STORAGE_URL + item.icon} alt={item?.name} />
            </div>
          )
      )}
    </div>
  );
};

export default SocialHandles;
