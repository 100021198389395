/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import mloukLogo from "../../assets/images/mloukLogo.svg";
import useViewport from "../../hooks/useViewport";

import { CgMenu } from "react-icons/cg";

import "./styles.scss";

const NavBar = () => {
  const { width } = useViewport();
  const [collapsed, setCollapsed] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(true);

  const navItems = [
    {
      url: "/#projects",
      label: "Projects",
    },
    {
      url: "/#services",
      label: "Services",
    },
    {
      url: "/#about",
      label: "About Us",
    },
    {
      url: "/#contact",
      label: "Contact Us",
    },
  ];

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }

      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      style={{ opacity: !show ? 0 : 1 }}
      className={[collapsed && width < 992 && "collapseNav", "navbarWrapper"]}
    >
      <Container style={{ paddingTop: 16 }}>
        <Navbar.Brand href="/">
          <img
            src={mloukLogo}
            style={{ width: 110, marginTop: 0 }}
            alt="Mlouk Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setCollapsed(!collapsed)}
        >
          <span>
            <CgMenu size={30} color="white"/>
          </span>
        </Navbar.Toggle>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="inner-nav">
            {navItems?.map((item, key) => (
              <div className="navItemContainer" key={key}>
                <Nav.Item
                  className="nav-item"
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                >
                  {item?.label}
                </Nav.Item>
                {width >= 992 && navItems.length - 1 !== key && (
                  <div className="verticalSeparator" />
                )}
              </div>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
