import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BusinessCard from "../BusinessCard";
import footerSquare from "../../assets/images/footerSquare.svg";
// import config from "../../network/config";
// import { useHistory } from "react-router-dom";
import "./styles.scss";

const Footer = ({ socialsData, footerData }) => {
  return (
    <div className="footerWrapper">
      <Container className="footerContainer">
        <img src={footerSquare} alt="square" className="footerSquare" />
        <div className="footerText">
          <p>{footerData.text}</p>
        </div>

        <Row>
          <Col xs={12} xl={7}>
            <Row>
              {footerData?.contacts?.map((item, key) => (
                <Col className="businessCardCol" key={key}>
                  <BusinessCard
                    email={item?.email}
                    country={item?.country}
                    phone={item?.phone}
                    location={item?.location}
                    url={item?.locationURL}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="socialsWrapper">
            {/* <div className="socialIconsWrapper">
              {socialsData?.map((item) => (
                <img
                  key={item?.id}
                  src={config?.WS_STORAGE_URL + item?.icon}
                  className="socialIcon"
                  alt={item?.id}
                  onClick={() => {
                    history.push((window.location.href = item.url));
                  }}
                />
              ))}
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
