import React from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";

const MloukButton = (props) => {
  function getTheme() {
    if (props.disabled) {
      return "#EBEBE4";
    }
    switch (props.kind) {
      case "cyan":
        return "#587BAD";
      case "blue":
        return "#326866";
      case "red":
        return "#991E11";
      default:
        return "#326866";
    }
  }

  return (
    <Button
      {...props}
      style={{
        ...props.style,
        borderColor: getTheme(),
        backgroundColor: getTheme(),
        boxShadow: "none",
        color: props.disabled ? "#000000" : "white",
      }}
      className="mloukButton"
    >
      <span>{props.text}</span>
    </Button>
  );
};

export default MloukButton;
