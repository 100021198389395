import React from "react";
import "./style.scss";

const TagButton = ({ variant, onClick, borderColor, style, selected }) => {
  return (
    <div
      className="tagButton"
      style={{ ...style, zIndex: 100 }}
      onClick={() => {
        onClick();
      }}
    >
      <p>{variant}</p>
      <div
        style={{
          backgroundColor: borderColor,
          opacity: selected && 1,
          bottom: selected ? 2 : 14,
        }}
        className={"borderLine"}
      />
    </div>
  );
};

export default TagButton;
