import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import useViewport from "../../hooks/useViewport";
import TestimonialCard from "../TestimonialCard";
import config from "../../network/config";

import "./styles.scss";

const TestimonialSwiper = ({ data }) => {
  const { width } = useViewport();

  function getNbOfElmts() {
    if (width < 576) {
      return 1.05;
    }
    if (width < 1000) {
      return 1.95;
    }
    return 3;
  }

  function getSpace() {
    if (width < 576) {
      return 1.05;
    }
    if (width < 1000) {
      return 1.95;
    }
    return 3;
  }

  return (
    <Swiper
      speed={400}
      initialSlide={1}
      spaceBetween={getSpace()}
      navigation={true}
      modules={[Navigation]}
      className="testimonialsSwiper"
      id="testimonialSwiperCarousel"
      direction={"horizontal"}
      loop={true}
      autoplay={5000}
      effect={"slide"}
      slidesPerView={getNbOfElmts()}
      centeredSlides={true}
      grabCursor={true}
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index} className={`swiperItem`}>
          <TestimonialCard
            name={item?.name}
            paragraph={item?.text}
            imgUrl={config.WS_STORAGE_URL + item?.image}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSwiper;
