/* eslint-disable */

import React, { useEffect, useState } from "react";
import "./styles.scss";

const Loading = ({ isLoading }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!loading);
  }, []);

  return (
    <div
      className="wrapper"
      style={{
        width: "100vw",
        height: "100vh",
        background: "black",
        position: "fixed",
        zIndex: isLoading ? 10000 : 0,
        transition: "0.5s ease",
        opacity: isLoading ? 1 : 0,
      }}
    >
      <svg
        className={`${loading && "active"}`}
        id="Component_1_1"
        data-name="Component 1 – 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="147.42999267578125"
        height="39.86600112915039"
        viewBox="0 0 147.43 39.866"
      >
        <defs>
          <clipPath id="clipPath">
            <rect
              id="Rectangle_6"
              data-name="Rectangle 6"
              width="147.43"
              height="39.866"
              fill="#fff"
              className="svg-elem-1"
            ></rect>
          </clipPath>
        </defs>
        <path
          id="Path_1"
          data-name="Path 1"
          d="M52.252,1.168H48.4v23.22H67.969V20.536H52.252Z"
          transform="translate(-1.184 -0.028)"
          fill="#fff"
          className="svg-elem-2"
        ></path>
        <g id="Group_2" data-name="Group 2">
          <g id="Group_1" data-name="Group 1" clipPath="url(#clipPath)">
            <path
              id="Path_2"
              data-name="Path 2"
              d="M115.873,12.881a8.128,8.128,0,0,1-.6,3.083,7.61,7.61,0,0,1-1.663,2.527,7.872,7.872,0,0,1-2.536,1.71,7.679,7.679,0,0,1-2.988.594,7.585,7.585,0,0,1-2.947-.594,7.854,7.854,0,0,1-2.534-1.71,7.618,7.618,0,0,1-1.664-2.527,8.121,8.121,0,0,1-.6-3.083V1.165H96.5V12.881a12.071,12.071,0,0,0,.866,4.533,10.869,10.869,0,0,0,2.515,3.765,11.625,11.625,0,0,0,3.766,2.593,11.391,11.391,0,0,0,8.96,0,11.7,11.7,0,0,0,3.741-2.591,10.878,10.878,0,0,0,2.515-3.765,12.078,12.078,0,0,0,.865-4.533V1.165h-3.851Z"
              transform="translate(-2.361 -0.028)"
              fill="#fff"
              className="svg-elem-3"
            ></path>
            <path
              id="Path_3"
              data-name="Path 3"
              d="M138.207,9.773,144.3,1.165h-4.8l-9.815,13.728V1.165h-3.851V26.79l10.052-13.737,9.535,11.335h5.084Z"
              transform="translate(-3.079 -0.028)"
              fill="#fff"
              className="svg-elem-4"
            ></path>
            <path
              id="Path_4"
              data-name="Path 4"
              d="M20.62,1.262l-8.778,8.869L0,.022V24.4H3.292L14.244,13.433l-.014-.014,6.406-6.371,8.8,8.654,7.906-7.054V24.359h3.851V0L29.513,10.13ZM8.909,13.035,3.852,18.148V8.586Z"
              transform="translate(0 0)"
              fill="#fff"
              className="svg-elem-5"
            ></path>
            <path
              id="Path_5"
              data-name="Path 5"
              d="M89.741,6.167a10.678,10.678,0,0,0-1.266-1.492A11.6,11.6,0,0,0,84.71,2.082a11.391,11.391,0,0,0-8.96,0,11.709,11.709,0,0,0-3.741,2.591,10.818,10.818,0,0,0-1.265,1.492,11.089,11.089,0,0,0-1.25,2.273,12.078,12.078,0,0,0-.862,4.484v.008a12.071,12.071,0,0,0,.863,4.484,11.038,11.038,0,0,0,1.249,2.273,10.822,10.822,0,0,0,1.266,1.492,11.625,11.625,0,0,0,3.766,2.593,11.391,11.391,0,0,0,8.96,0,11.688,11.688,0,0,0,3.741-2.591,10.7,10.7,0,0,0,1.265-1.492,11.047,11.047,0,0,0,1.25-2.273,12.1,12.1,0,0,0,.863-4.484v-.008a12.087,12.087,0,0,0-.863-4.484,11.089,11.089,0,0,0-1.25-2.273m-1.764,7.372a8.046,8.046,0,0,1-.569,2.425c-.074.181-.153.359-.239.532a7.527,7.527,0,0,1-1.424,1.995,7.872,7.872,0,0,1-2.536,1.71,7.679,7.679,0,0,1-2.988.594,7.576,7.576,0,0,1-2.946-.594,7.847,7.847,0,0,1-2.535-1.71,7.6,7.6,0,0,1-1.663-2.527,8.047,8.047,0,0,1-.583-2.64c-.007-.132-.011-.264-.011-.4a8.166,8.166,0,0,1,.457-2.677c.043-.121.089-.241.137-.36A7.613,7.613,0,0,1,74.74,7.364a7.868,7.868,0,0,1,2.535-1.71,7.684,7.684,0,0,1,2.989-.594,7.576,7.576,0,0,1,2.946.594l.176.076a7.864,7.864,0,0,1,2.219,1.5l.14.137h0A7.5,7.5,0,0,1,87.17,9.358q.127.259.239.532a8.046,8.046,0,0,1,.582,2.64c.007.132.011.264.012.4,0,.066,0,.133,0,.2,0,.139-.011.276-.021.413"
              transform="translate(-1.679 -0.028)"
              fill="#fff"
              className="svg-elem-6"
            ></path>
            <path
              id="Path_6"
              data-name="Path 6"
              d="M41.012,38.575H39.138v1.95h-1.25V33.9c1.042,0,2.083-.009,3.124-.009a2.344,2.344,0,1,1,0,4.684M39.138,37.43h1.874a1.174,1.174,0,1,0,0-2.347H39.138Z"
              transform="translate(-0.927 -0.829)"
              fill="#fff"
              className="svg-elem-7"
            ></path>
            <path
              id="Path_7"
              data-name="Path 7"
              d="M50.79,40.525H49.3L47.374,38.31h-1.2v2.214h-1.25V33.89c1.051,0,2.1.01,3.152.01a2.2,2.2,0,0,1,2.385,2.195,1.966,1.966,0,0,1-1.675,2.083L50.79,40.44Zm-4.618-5.461v2.12h1.9a1.062,1.062,0,1,0,0-2.12Z"
              transform="translate(-1.099 -0.829)"
              fill="#fff"
              className="svg-elem-8"
            ></path>
            <path
              id="Path_8"
              data-name="Path 8"
              d="M58.43,37.247a3.4,3.4,0,1,1-6.8.01,3.4,3.4,0,1,1,6.8-.01m-5.574.028a2.174,2.174,0,1,0,4.344-.01,2.18,2.18,0,1,0-4.344.01"
              transform="translate(-1.263 -0.826)"
              fill="#fff"
              className="svg-elem-9"
            ></path>
            <path
              id="Path_9"
              data-name="Path 9"
              d="M65.837,37.155a3.139,3.139,0,0,1-3.313,3.369h-2.6V33.9h2.6a3.129,3.129,0,0,1,3.313,3.255m-4.676,2.168h1.363a2.12,2.12,0,0,0,0-4.24H61.161Z"
              transform="translate(-1.466 -0.829)"
              fill="#fff"
              className="svg-elem-10"
            ></path>
            <path
              id="Path_10"
              data-name="Path 10"
              d="M73.105,33.91V37.7a2.691,2.691,0,0,1-2.868,2.963A2.763,2.763,0,0,1,67.294,37.7V33.91h1.24V37.7a1.625,1.625,0,0,0,1.713,1.827c1.032,0,1.618-.691,1.618-1.827V33.91Z"
              transform="translate(-1.647 -0.829)"
              fill="#fff"
              className="svg-elem-11"
            ></path>
            <path
              id="Path_11"
              data-name="Path 11"
              d="M80.8,39.688a3.493,3.493,0,0,1-2.518.984,3.257,3.257,0,0,1-3.511-3.416,3.334,3.334,0,0,1,3.511-3.492,3.419,3.419,0,0,1,2.433,1.013l-.833.8a2.278,2.278,0,0,0-1.6-.624A2.172,2.172,0,0,0,76,37.256,2.124,2.124,0,0,0,78.285,39.5a2.441,2.441,0,0,0,1.666-.672Z"
              transform="translate(-1.83 -0.826)"
              fill="#fff"
              className="svg-elem-12"
            ></path>
            <path
              id="Path_12"
              data-name="Path 12"
              d="M83.744,35.036h-2.1V33.9h5.451v1.136h-2.1v5.489h-1.25Z"
              transform="translate(-1.998 -0.829)"
              fill="#fff"
              className="svg-elem-13"
            ></path>
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="1.24"
              height="6.625"
              transform="translate(86.317 33.07)"
              fill="#fff"
              className="svg-elem-14"
            ></rect>
            <path
              id="Path_13"
              data-name="Path 13"
              d="M98.129,37.247a3.4,3.4,0,1,1-6.8.01,3.4,3.4,0,1,1,6.8-.01m-5.574.028a2.174,2.174,0,1,0,4.344-.01,2.18,2.18,0,1,0-4.344.01"
              transform="translate(-2.235 -0.826)"
              fill="#fff"
              className="svg-elem-15"
            ></path>
            <path
              id="Path_14"
              data-name="Path 14"
              d="M104.063,33.89h1.25v6.635h-.777v.01l-3.483-4.477v4.467H99.8V33.9h1.013l3.247,4.107Z"
              transform="translate(-2.442 -0.829)"
              fill="#fff"
              className="svg-elem-16"
            ></path>
            <path
              id="Path_15"
              data-name="Path 15"
              d="M111.275,35.493a1.9,1.9,0,0,0-1.572-.738c-.927,0-1.372.388-1.372.881,0,.577.681.738,1.476.832,1.382.171,2.669.531,2.669,2.111,0,1.476-1.306,2.11-2.782,2.11a2.751,2.751,0,0,1-2.887-1.627l1.041-.539a1.888,1.888,0,0,0,1.864,1.05c.785,0,1.524-.274,1.524-.993,0-.625-.653-.88-1.534-.975-1.353-.161-2.6-.521-2.6-2.007,0-1.363,1.344-1.922,2.566-1.931a2.678,2.678,0,0,1,2.6,1.315Z"
              transform="translate(-2.614 -0.824)"
              fill="#fff"
              className="svg-elem-17"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Loading;
