import React from "react";
import { Modal } from "react-bootstrap";

import { useFormik } from "formik";
import Input from "../../components/Input";
import MloukButton from "../../components/MloukButton";
import TextArea from "../../components/TextArea";
import emailjs from "@emailjs/browser";

import "./styles.scss";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { postContact } from "../../network/ApiAxios";
import { IoClose } from "react-icons/io5";

const ContactSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email("Invalid email").required(),
  message: Yup.string().required(),
});

const ContactPage = (props) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact_number: "",
      company_name: "",
      message: "",
    },
    validationSchema: ContactSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      postContact(values)
        .then((res) => {
          emailjs
            .send(
              process.env.REACT_APP_SERVICE_KEY,
              process.env.REACT_APP_TEMPLATE_KEY,
              values,
              process.env.REACT_APP_EMAIL_PUBLIC_KEY
            )
            .then(
              (result) => {
                toast.success("Message sent!");
                props.setIsOpenMessage(false);
              },
              (error) => {
                toast.error("We have encountered an error.");
              }
            );
        })
        .catch((err) => {
          toast.error("We have encountered an error.");
        });
    },
  });
  return (
    <Modal size="md" centered {...props}>
      <div
        lg={5}
        className="contactFormOverlay"
        style={{
          background: "black",
          width: "100%",
          height: "100%",
          padding: 32,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IoClose
            size={32}
            color="white"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setIsOpenMessage(false);
            }}
          />
        </div>
        <div className="contactFormOverlayContainer">
          <Input
            placeholder={"Name"}
            name="name"
            onChange={formik.handleChange("name")}
            value={formik.values.name}
          />
          <Input
            placeholder={"Email"}
            name="email"
            type={"email"}
            onChange={formik.handleChange("email")}
            value={formik.values.email}
          />
          <Input
            placeholder={"Contact Number"}
            name="contact_number"
            onChange={formik.handleChange("contact_number")}
            value={formik.values.contact_number}
          />
          <Input
            placeholder={"Company Name"}
            name="company_name"
            onChange={formik.handleChange("company_name")}
            value={formik.values.company_name}
          />
          <TextArea
            placeholder={"Type your message"}
            name="message"
            onChange={formik.handleChange("message")}
            value={formik.values.message}
          />
          <MloukButton
            kind="cyan"
            text="Submit"
            style={{ marginTop: 10 }}
            disabled={!formik.isValid}
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactPage;
