import axios from "axios";
import config from "./config.js";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

export const getHeroSlider = async () => await instance.get("hero_slider");
export const getProjects = async () =>
  await instance.get(
    "projects?fields=*,categories.*,categories.categories_id.*"
  );
export const getCategories = async () => await instance.get("categories");
export const getTeam = async () => await instance.get("team");
export const getTestimonials = async () => await instance.get("testimonials");
export const getAboutUs = async () => await instance.get("about_us");
export const getClients = async () => await instance.get("clients");
export const getSocials = async () => await instance.get("socials");
export const getFooter = async () => await instance.get("footer");
export const getServices = async () => await instance.get("services");
export const getContactSection = async () => await instance.get("ContactSection");
export const postContact = async (values) =>
  await instance.post("Messages", values);
export const getTeammate = async (id) =>
  await instance.get("https://api.mloukproductions.com/items/team/" + id);
