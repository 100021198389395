import React from "react";
import { Modal } from "react-bootstrap";
import TeammateDetails from "../TeammateDetails";
import "./styles.scss";

const TeammatePopUp = (props) => {
  return (
    <Modal size="xl" centered {...props} className="teammateDetailsModal">
      <div className="teammateDetailsWrapper">
        <TeammateDetails
          selectedTeammate={props.selectedTeammate}
          setSelectedTeammate={props.setSelectedTeammate}
        />
      </div>
    </Modal>
  );
};

export default TeammatePopUp;
