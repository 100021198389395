import React, { useState } from "react";
import { Container } from "react-bootstrap";

import ProjectCarousel from "../ProjectCarousel";
import TagButton from "../TagButton";
import "./styles.scss";

const Projects = ({
  data,
  isLoading,
  setSelectedYoutubeLink,
  splitProjectsData,
}) => {
  const [chosenTag, setChosenTag] = useState("all");

  return (
    <div className="projectsWrapper" id="projects">
      <Container className="projectsContainer">
        <h1 className="projectsHeading">Projects</h1>
        <div className="subTitles">
          <TagButton
            selected={chosenTag === "all"}
            variant={"all"}
            onClick={() => setChosenTag("all")}
            borderColor={"#ABBDD6"}
          />
          {data?.categories?.map((item) => (
            <TagButton
              variant={item?.name}
              borderColor={item?.color}
              onClick={() => setChosenTag(item?.id)}
              key={item?.id}
              selected={chosenTag === item?.id}
            />
          ))}
        </div>
      </Container>
      <ProjectCarousel
        setSelectedYoutubeLink={setSelectedYoutubeLink}
        chosenTag={chosenTag}
        data={data}
        splitProjects={splitProjectsData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Projects;
