import React from "react";
import { Carousel } from "react-bootstrap";

import "./styles.scss";
import config from "../../network/config";
import SocialHandles from "../SocialHandles";
import arrowDown from "../../assets/images/down.png";

const Slideshow = ({ data, socialsData }) => {
  return (
    <div className="slideshowContainer">
      <SocialHandles socialsData={socialsData} />
      <Carousel
        touch={true}
        controls={false}
        indicators={false}
        interval={3000}
      >
        {data.map((item, key) => (
          <Carousel.Item className="carouselItem" key={key}>
            <div
              className="d-block w-100 carouselImage"
              style={{
                backgroundImage: `url(${config.WS_STORAGE_URL}${item?.image})`,
              }}
            />

            <Carousel.Caption className="carouselCaptionContainer">
              <div className="carouselCaption">
                <h1 className="subheading">{item?.title_1}</h1>
                <h2 className="heading">{item?.title_2}</h2>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <a className="arrowDownWrapper" href="/#projects">
        <img src={arrowDown} className="arrowDown" />
      </a>
    </div>
  );
};

export default Slideshow;
