import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FlipCard from "../../components/FlipCard";
import "./style.scss";

import Creative from "../../assets/images/Creative.jpg";
import Production from "../../assets/images/Production.jpg";
import Post from "../../assets/images/Post-production.jpg";
import { useMediaQuery } from "beautiful-react-hooks";

const Services = ({ servicesData }) => {
  const [openFlip, setOpenFlip] = useState(null);
  const isSmall = useMediaQuery("(max-width: 767px)");

  function getColor(key) {
    switch (key) {
      case 0:
        return "#000000";
      case 1:
        return "#3D5764";
      case 2:
        return "#617D8F";
      default:
        return "#000000";
    }
  }

  function getBg(key) {
    switch (key) {
      case 0:
        return Creative;
      case 1:
        return Production;
      case 2:
        return Post;
      default:
        break;
    }
  }

  return (
    <>
      <h1 className="servicesHeadingMobile">SERVICES</h1>
      <Container className="servicesContainer" fluid id="services">
        <h1 className="servicesHeading">SERVICES</h1>
        <Row className="servicesRow">
          {servicesData.map((item, key) => (
            <Col
              xs={12}
              md={4}
              className="serviceCol"
              key={key}
              style={{
                backgroundColor: getColor(key),
                backgroundImage:
                  openFlip !== key && !isSmall && `url(${getBg(key)})`,
                whiteSpace: "pre-wrap",
                // backgroundPosition: "0 -20px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <FlipCard
                setOpenFlip={setOpenFlip}
                index={key}
                services={item.services}
                frontTitle={item.name}
                borderColor="#326866"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Services;
