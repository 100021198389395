import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import "./styles.scss";

const FlipCard = ({
  borderColor,
  frontTitle,
  services,
  index,
  setOpenFlip,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setIsFlipped(true);
        setOpenFlip(index);
      }}
      onMouseLeave={() => {
        setIsFlipped(false);
        setOpenFlip(null);
      }}
      className="flipCard"
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="vertical"
        containerClassName="flipCardContainer"
      >
        <div className="flipCardFront">
          <div className="flipCardTitle" style={{ borderColor: borderColor }}>
            <p>{frontTitle}</p>
          </div>
        </div>

        <div className="flipCardBack">
          <div className="flipCardTitle" style={{ borderColor: borderColor }}>
            <p>{frontTitle}</p>
          </div>
          {services.map((item, key) => (
            <div className="flipCardList" key={key}>
              <p className="flipCardListHeading">{item.title}</p>

              <div
                className="flipCardListItem"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          ))}
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default FlipCard;
