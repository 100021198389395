import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./styles.scss";
import config from "../../network/config";
import useViewport from "../../hooks/useViewport";

const ClientCarousel = ({ data }) => {
  const { width } = useViewport();

  return (
    <Swiper
      modules={[Autoplay]}
      grabCursor
      spaceBetween={40}
      slidesPerView="auto"
      freeMode
      speed={3000}
      autoplay={{
        delay: 1,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
      }}
    >
      {data?.map((item, key) => (
        <SwiperSlide
          key={key}
          style={{
            width: "fit-content",
          }}
          className="no-select"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: width > 767 ? 200 : 166,
              height: width > 767 ? 200 : 166,
            }}
          >
            <img
              alt={item?.name}
              src={`${config.WS_STORAGE_URL}${item?.image}`}
              key={item?.id}
              className="clientImage"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ClientCarousel;
