import React from "react";
// import Teammates from "../../components/Teammates";
import "./styles.scss";

const About = ({ data, setSelectedTeammate }) => {
  return (
    <div className="aboutUs" id="about">
      <div className="aboutUsImage" />
      <div className="aboutUsOverlayWrapper">
        <div className="aboutUsOverlayContainer">
          <h1 className="aboutUsOverlayHeading">ABOUT US</h1>
          <div className="aboutUsOverlay">
            <div className="aboutUsOverlaySection">
              <h2 className=" aboutUsOverlaySectionHeading padded">
                {data?.about?.title}
              </h2>
              <h6 className="aboutUsOverlaySectionParagraph">
                {data?.about?.text}
              </h6>
            </div>
            {/* <div className="aboutUsOverlaySection">
              <h2 className="aboutUsOverlaySectionHeading">TEAM</h2>
            </div> */}
          </div>
        </div>
        <div className="caramelOverlay">
          <div style={{ height: 240, width: "100%" }} />
          {/* <Teammates
            data={data?.team}
            setSelectedTeammate={setSelectedTeammate}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default About;
